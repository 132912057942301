import Vue from 'vue';
import {
  $ADD_LEAGUE_LIST,
  $DELETE_LEAGUE,
  $LINK_MATCH_LIST_TO_LEAGUE,
  $LINK_MATCH_TO_LEAGUE,
  $UNLINK_MATCH_FROM_LEAGUE,
  $UPDATE_LEAGUE_COLLAPSE_STATES,
  $TOGGLE_LEAGUE,
  $SET_COLLAPSE_LEAGUES_BY_SPORT,
  $SET_LEAGUE_COLLAPSE_STATE,
} from "@/stores/LiveLeagueStore/constants";
import { sanitizeIndex } from "@/globals/utils/arrays";

export default {
  [$LINK_MATCH_TO_LEAGUE](state, match) {
    const { displayOrder, id, leagueId } = match;
    const league = state.leagues[leagueId];

    if (!league) return;

    const index = sanitizeIndex(league.matches, displayOrder);

    league.matches.splice(index, 0, id);
  },
  [$LINK_MATCH_LIST_TO_LEAGUE](state, matches) {
    Object.values(matches).forEach((match) => {
      const { displayOrder, id, leagueId } = match;
      const league = state.leagues[leagueId];
      const index = sanitizeIndex(league.matches, displayOrder);

      league.matches.splice(index, 0, id);
    });
  },
  [$UNLINK_MATCH_FROM_LEAGUE]: (state, { matchId, leagueId }) => {
    const league = state.leagues[leagueId];

    if (!league) return;
    league.matches = league.matches.filter((id) => id !== matchId);
  },
  [$DELETE_LEAGUE]: (state, { id }) => {
    Vue.delete(state.leagues, id);
  },
  [$UPDATE_LEAGUE_COLLAPSE_STATES]: (state, leagues) => {
    Object
      .values(leagues)
      .map((league) => Vue.set(state.leagues, league.id, league));
  },
  [$ADD_LEAGUE_LIST]: (state, leagues) => {
    Object
      .values(leagues)
      .map((league) => Vue.set(state.leagues, league.id, league));
  },
  [$TOGGLE_LEAGUE]: (state, { id, category, toggleState = undefined }) => {
    let value;

    if (toggleState !== undefined) {
      value = toggleState;
    } else {
      value = !(state.collapsed[category][id] ?? true);
    }

    Vue.set(state.collapsed[category], id, value);
  },
  [$SET_LEAGUE_COLLAPSE_STATE]: (state, { id, category, value }) => {
    Vue.set(state.collapsed[category], id, value);
  },
  [$SET_COLLAPSE_LEAGUES_BY_SPORT]: (state, { id, category, value }) => {
    const collapsed = Object.values(state.leagues)
      .filter(({ sportId }) => sportId === id)
      .reduce((acc, { id: leagueId }) => {
        acc[leagueId] = value;
        return acc;
      }, {});

    collapsed[`FAVORITES.${id}`] = value;

    Vue.set(state.collapsed, category, { ...state.collapsed[category], ...collapsed });
  },
};
