import {
  GET_LEAGUE_BY_ID,
  LEAGUES_GET_BY_SPORT_LIMITED,
  GET_FILTERED_LEAGUES_BY_SPORT_ID,
  IS_ALL_LEAGUES_OPEN,
  IS_LEAGUE_COLLAPSED,
  GET_FILTERED_LEAGUE_IDS_BY_SPORT,
  GET_LIMITED_FILTERED_LEAGUE_IDS_BY_SPORT,
} from "@/stores/LiveLeagueStore/constants";
import { BY_DISPLAY_ORDER } from "@/globals/sportdata/comparators";
import { APP_CONF_GET_CONF_VALUE } from "@/stores/AppConfigurationStore/constants";
import { getters } from "@/stores/LiveMatchStore";

export default {
  [GET_FILTERED_LEAGUES_BY_SPORT_ID](state, moduleGetters) {
    return (lookupSportId) => {
      const leagues = Object
        .values(state.leagues)
        .filter(({ sportId }) => lookupSportId === sportId)
        .filter(({ id: leagueId }) => {
          // eslint-disable-next-line max-len
          const filteredMatchCount = moduleGetters[getters.GET_FILTERED_MATCH_COUNT_BY_LEAGUE](leagueId);

          return filteredMatchCount > 0;
        })
        .sort(BY_DISPLAY_ORDER)
        .map(({ matches, displayOrder, ...league }) => Object.freeze(
          { ...league, matchCount: matches.length },
        ));

      return leagues;
    };
  },
  [GET_FILTERED_LEAGUE_IDS_BY_SPORT](state, moduleGetters) {
    return (sportId) => moduleGetters[GET_FILTERED_LEAGUES_BY_SPORT_ID](sportId).map((league) => league.id);
  },
  [LEAGUES_GET_BY_SPORT_LIMITED](state, moduleGetters, rootState, rootGetters) {
    return (sportId) => {
      const OVERVIEW_MATCH_LIMIT_PER_SPORT = rootGetters[`AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`](`overviewMatchLimitPerSport`);
      const filteredMatches = moduleGetters[GET_FILTERED_LEAGUES_BY_SPORT_ID](sportId);
      let sportMatchCount = 0;

      return filteredMatches.filter((league) => {
        sportMatchCount += league.matchCount;
        return sportMatchCount - league.matchCount <= OVERVIEW_MATCH_LIMIT_PER_SPORT;
      });
    };
  },
  [GET_LIMITED_FILTERED_LEAGUE_IDS_BY_SPORT](state, moduleGetters) {
    return (sportId) => moduleGetters[LEAGUES_GET_BY_SPORT_LIMITED](sportId).map((league) => league.id);
  },
  [GET_LEAGUE_BY_ID](state) {
    return (leagueId) => state.leagues[leagueId] || null;
  },
  [IS_ALL_LEAGUES_OPEN](state) {
    return ({ id, category }) => {
      const normalLeaguesOpen = Object
        .values(state.leagues)
        .filter(({ sportId }) => sportId === id)
        .some((league) => !!(state.collapsed[category][league.id] ?? true));
      const favoriteLeagueId = `FAVORITES.${id}`;
      const favoriteLeagueOpen = !!(state.collapsed[category][favoriteLeagueId] ?? false);

      return !normalLeaguesOpen && !favoriteLeagueOpen;
    };
  },
  [IS_LEAGUE_COLLAPSED]({ collapsed }) {
    return ({ id, category }) => !!(collapsed[category][id] ?? true);
  },
};
