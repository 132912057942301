import { ReplaySubject } from 'rxjs';

class Lifecycle {
  constructor() {
    if (!Lifecycle.instance) {
      Lifecycle.instance = this;
      this.lifecycleSubject$ = new ReplaySubject();
    }

    return Lifecycle.instance;
  }

  $on(hook, callback) {
    this.lifecycleSubject$.subscribe(({ hook: firedHook, params }) => {
      if (hook === firedHook) callback(params);
    });
  }

  $emit(hook, params = {}) {
    this.lifecycleSubject$.next({ hook, params });
  }
}

export default new Lifecycle();
