import {
  APP_CONF_FETCH_DONE,
  APP_CONF_FETCH_ERROR,
  APP_CONF_FETCH_PENDING,
} from '@/stores/AppConfigurationStore/constants';

export default {
  [APP_CONF_FETCH_PENDING](state) {
    state.isError = false;
    state.error = null;
    state.isLoading = true;
  },
  [APP_CONF_FETCH_DONE](state, configuration) {
    state.isError = false;
    state.error = null;
    state.isLoading = false;
    state.configuration = configuration;
  },
  [APP_CONF_FETCH_ERROR](state, error) {
    state.isError = true;
    state.error = error;
    state.isLoading = false;
    state.error = error;
  },
};
