import Vue from 'vue';

export const mutationNames = {
  SET_TOP_MARKET: `SET_TOP_MARKET`,
  UNSET_TOP_MARKET: `UNSET_TOP_MARKET`,
};
export const actionNames = {
  SET_TOP_MARKET: `setTopMarket`,
  UNSET_TOP_MARKET: `unsetTopMarket`,
  ADD_TOP_MARKET_LIST: `addTopMarketList`,
};

export const getterNames = {
  GET_TOP_MARKET_BY_ID: `getTopMarketById`,
  GET_TOP_MARKETS_BY_MATCH_ID: `getTopMarketsByMatchId`,
};

const LiveTopMarketStore = {
  state: {
    topMarkets: {},
  },
  mutations: {
    [mutationNames.SET_TOP_MARKET]: (state, topMarket) => {
      Vue.set(state.topMarkets, topMarket.id, topMarket);
    },
    [mutationNames.UNSET_TOP_MARKET]: (state, { matchId, slotId }) => {
      const topMarketId = `${matchId}_${slotId}`;

      Vue.delete(state.topMarkets, topMarketId);
    },
  },
  actions: {
    [actionNames.ADD_TOP_MARKET_LIST]({ commit }, topMarkets) {
      Object
        .values(topMarkets)
        .forEach((topMarket) => commit(mutationNames.SET_TOP_MARKET, topMarket));
    },
    [actionNames.SET_TOP_MARKET]({ commit }, { matchId, slotId, topMarket }) {
      if (!matchId || !slotId) {
        // eslint-disable-next-line no-undef
        if (NODE_ENV !== `production`) {
          // eslint-disable-next-line no-console
          console.warn(`Attempt to set top market (slot: ${slotId}) for non-existing matchId ${matchId}`);
        }
        return;
      }

      commit(mutationNames.SET_TOP_MARKET, topMarket);
    },
    [actionNames.UNSET_TOP_MARKET]({ commit }, { matchId, slotId }) {
      if (!matchId || !slotId) {
        // eslint-disable-next-line no-undef
        if (NODE_ENV !== `production`) {
          // eslint-disable-next-line no-console
          console.warn(`Attempt to unset top market (slot: ${slotId}) for non-existing matchId ${matchId}`);
        }
        return;
      }

      commit(mutationNames.UNSET_TOP_MARKET, { matchId, slotId });
    },
  },
  getters: {
    [getterNames.GET_TOP_MARKETS_BY_MATCH_ID](state) {
      return (matchId) => {
        const { topMarkets } = state;
        const matchTopMarkets = [
          topMarkets[`${matchId}_1`],
          topMarkets[`${matchId}_2`],
          topMarkets[`${matchId}_3`],
        ].filter((topMarket) => !!topMarket);

        return matchTopMarkets.map(({ marketId }) => marketId);
      };
    },
    [getterNames.GET_TOP_MARKET_BY_ID](state) {
      return (topMarketId) => (topMarketId && state.topMarkets[topMarketId]) || {};
    },
  },
};

export default LiveTopMarketStore;
