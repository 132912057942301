import * as types from './mutations';
import * as getters from './getters';

export const TOP_MENU_HEIGHT = 45;
export const FULL_HD_WIDTH = 1920;

const graphicsStore = {
  namespaced: true,
  state: {
    // state of browser window
    browserWindow: {
      width: 0,
      height: 0,
    },
    webMessageContainerHeight: 0,
    disconnectionViewMenuHeight: 0,
    offerFilterHeight: 0,
  },
  mutations: {
    [types.SET_DISCONNECTION_VIEW_MENU_HEIGHT](state, height) {
      state.disconnectionViewMenuHeight = height;
    },
    [types.SAVE_BROWSER_WINDOW_STATE](state, browserWindow) {
      state.browserWindow = browserWindow;
    },
    [types.WEB_MESSAGE_SET_CONTAINER_HEIGHT](state, webMessageContainerHeight) {
      state.webMessageContainerHeight = webMessageContainerHeight;
    },
  },
  getters: {
    [getters.IS_WINDOW_FULL_HD_WIDE](state) {
      return (state.browserWindow.width >= FULL_HD_WIDTH);
    },
    [getters.GET_APP_COLUMN_HEIGHT](state) {
      return Math.max(0, state.browserWindow.height - TOP_MENU_HEIGHT);
    },
    [getters.GET_WINDOW_DIMENSIONS](state) {
      return state.browserWindow;
    },
  },
};

export default graphicsStore;
