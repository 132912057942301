export const USER_MESSAGE_SHOW_MODAL = `USER_MESSAGE_SHOW_MODAL`;
export const USER_MESSAGE_QUEUE_MESSAGE = `USER_MESSAGE_QUEUE_MESSAGE`;
/**
 * Arbitrary id that is used internally to identify a message of type GDPR
 * Reason for such constans is that there is no exact `templateId` in IMS
 * that is used to identify GDPR message but as a GDPR message is considered
 * any message whose `templateId` constains `GDPR` substring
 * @type {number}
 */

export const USER_MESSAGE_TYPE_TC = `SessionValidationByTCVersionData`;
export const USER_MESSAGE_TYPE_CHANGE_PASS = `SessionValidationByPasswordChangeData`;
export const USER_MESSAGE_TYPE_VERIFY_NUMBER = `userVerifyNumber`;
export const USER_MESSAGE_TYPE_MANDATORY_USERNAME_CHANGE = `mandatoryUserNameChange`;
export const USER_MESSAGE_TYPE_GENERAL = `USER_MESSAGE_TYPE_GENERAL`;
export const USER_MESSAGE_TYPE_RVO = `RVO`;
export const USER_MESSAGE_TYPE_GDPR = `GDPR`;
export const USER_MESSAGE_TYPE_BONUSES = `sbBonusMessage`;

export const USER_MESSAGE_SPECIAL_TYPE_RGL = `RGL_MODAL_SEEN`;

export const COUNTDOWN_TIME_FOR_MESSAGE_BUTTON_MILLIS = 5000;
