import * as mutations from './mutations';
import * as getters from './getters';
import * as actions from './actions';

export default {
  namespaced: true,
  state: {
    chatOpen: false,
    scriptAvailable: false,
  },
  mutations: {
    [mutations.CHAT_VISIBILITY](state, chatVisibility) {
      state.chatOpen = chatVisibility;
    },
    [mutations.SCRIPT_AVAILABILITY](state, scriptAvailability) {
      state.scriptAvailable = scriptAvailability;
    },
  },
  actions: {
    [actions.CHAT_VISIBILITY_ACTIONS]({ commit }, data) {
      commit(mutations.CHAT_VISIBILITY, data);
    },
    [actions.CHAT_SCRIPT_ACTIONS]({ commit }, data) {
      commit(mutations.SCRIPT_AVAILABILITY, data);
    },
  },
  getters: {
    [getters.IS_CHAT_VISIBLE](state) {
      return state.chatOpen;
    },
    [getters.IS_CHAT_SCRIPT_LOADED](state) {
      return state.scriptAvailable;
    },
  },
};
