import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const initialState = {
  isError: false,
  error: null,
  isLoading: false,
  configuration: {},
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
  getters,
};
