// getters
export const APP_CONF_GET_CONF_VALUE = `getConfigurationValue`;
export const IS_FEATURE_SUPPORTED = `isFeatureSupported`;

// actions

// mutations
export const APP_CONF_FETCH_PENDING = `FETCH_CONFIGURATION_PENDING`;
export const APP_CONF_FETCH_DONE = `FETCH_CONFIGURATION_DONE`;
export const APP_CONF_FETCH_ERROR = `FETCH_CONFIGURATION_ERROR`;
