import Vue from 'vue';

export const types = {
  CREATE_MARKET: `CREATE_MARKET`,
  UPDATE_MARKET: `UPDATE_MARKET`,
  PATCH_MARKET: `PATCH_MARKET`,
  DELETE_MARKET: `DELETE_MARKET`,
  ADD_MARKET_LIST: `ADD_MARKET_LIST`,
  DELETE_MARKET_LIST: `DELETE_MARKET_LIST`,
  DELETE_MARKET_ROW: `DELETE_MARKET_ROW`,
  ACTUALIZE_MARKETS_MAP: `ACTUALIZE_MARKETS_MAP`,
};

export const actions = {
  CREATE_MARKET: `createMarket`,
  UPDATE_MARKET: `updateMarket`,
  PATCH_MARKET: `PATCH_MARKET`,
  DELETE_MARKET: `deleteMarket`,
  ADD_MARKET_LIST: `addMarketList`,
  DELETE_MARKET_LIST: `deleteMarketList`,
  DELETE_MARKET_ROW: `deleteMarketRow`,
};

export const getters = {
  GET_MARKET_BY_ID: `getMarketById`,
  GET_MARKET_IDS_BY_MARKET_GROUP_ID_AND_MATCH_ID: `GET_MARKET_IDS_BY_MARKET_GROUP_ID_AND_MATCH_ID`,
  GET_ODDS_BY_MARKET_ROW_ID: `getOddsByMarketRowId`,
  GET_MARKET_BY_MARKET_ROW_ID: `GET_MARKET_BY_MARKET_ROW_ID`,
  GET_MATCH_ID_BY_MARKET_ID: `getMatchIdByMarketId`,
  IS_MARKET_SUPPORTIVE_TO_SELECTED: `IS_MARKET_SUPPORTIVE_TO_SELECTED`,
  CHECK_MARKET_SUPPORTIVENESS_BY_IDS: `CHECK_MARKET_SUPPORTIVENESS_BY_IDS`,
  IS_SAME_EVENT_BY_MARKET_IDS: `IS_SAME_EVENT_BY_MARKET_IDS`,
  GET_COMBINABLE_MARKETS: `GET_COMBINABLE_MARKETS`,
  GET_SELECTED_MARKETS: `GET_SELECTED_MARKETS`,
  GET_COMBINABLE_MARKETS_BY_MATCH_ID: `GET_COMBINABLE_MARKETS_BY_MATCH_ID`,
};

function checkIfSameGroup(group1, group2) {
  // eslint-disable-next-line no-bitwise
  return !((group1 & group2) === 0);
}

const liveMarketStore = {
  state: {
    markets: {},
    marketsMapping: {},
  },
  mutations: {
    [types.PATCH_MARKET]: (state, { id, field, value }) => {
      Vue.set(state.markets[id], field, value);
    },
    [types.CREATE_MARKET]: (state, market) => {
      Vue.set(state.markets, market.id, market);
    },
    [types.UPDATE_MARKET]: (state, market) => {
      if (state.markets[market.id]) {
        Object.keys(market).forEach((key) => {
          if (market[key] !== state.markets[market.id][key]) {
            Vue.set(state.markets[market.id], key, market[key]);
          }
        });
      } else {
        Vue.set(state.markets, market.id, market);
      }
    },
    [types.DELETE_MARKET]: (state, { id }) => {
      Vue.delete(state.markets, id);
      Vue.delete(state.marketsMapping, id);
    },
    [types.ADD_MARKET_LIST]: (state, markets) => {
      // TODO: the conditional statement is temporary,
      // proper solution to bug when markets is undefined/null should be found
      if (Object.prototype.toString.call(markets) === `[object Object]`) {
        Object
          .values(markets)
          .forEach((market) => Vue.set(state.markets, market.id, market));
      }
    },
    [types.DELETE_MARKET_LIST]: (state, marketIds) => {
      marketIds.forEach((marketId) => Vue.delete(state.markets, marketId));
    },
    [types.DELETE_MARKET_ROW]: (state, { marketRowId }) => {
      const marketId = state.marketsMapping[marketRowId];
      const market = state.markets[marketId];

      if (market) {
        market.marketRows = market.marketRows
          .filter((marketRowItemId) => marketRowId !== marketRowItemId);

        Vue.delete(state.marketsMapping, marketRowId);
      }
    },
    [types.ACTUALIZE_MARKETS_MAP]: (state, markets) => {
      Object
        .values(markets)
        .forEach(({ marketMapping }) => Object
          .keys(marketMapping)
          .forEach((key) => {
            if (state.marketsMapping[key] !== marketMapping[key]) {
              Vue.set(state.marketsMapping, key, marketMapping[key]);
            }
          }));
    },
  },
  actions: {
    [actions.CREATE_MARKET]({ commit }, market) {
      commit(types.CREATE_MARKET, market);
      commit(types.ACTUALIZE_MARKETS_MAP, { [market.id]: market });
    },
    [actions.UPDATE_MARKET]({ commit }, market) {
      commit(types.UPDATE_MARKET, market);
      commit(types.ACTUALIZE_MARKETS_MAP, { [market.id]: market });
    },
    [actions.PATCH_MARKET]({ commit }, { id, field, value }) {
      commit(types.PATCH_MARKET, { id, field, value });
    },
    [actions.DELETE_MARKET]({ commit }, data) {
      commit(types.DELETE_MARKET, data);
    },
    [actions.ADD_MARKET_LIST]({ commit }, data) {
      commit(types.ADD_MARKET_LIST, data);
      commit(types.ACTUALIZE_MARKETS_MAP, data);
    },
    [actions.DELETE_MARKET_LIST]({ commit }, data) {
      commit(types.DELETE_MARKET_LIST, data);
      return data;
    },
    [actions.DELETE_MARKET_ROW]({ commit }, data) {
      commit(types.DELETE_MARKET_ROW, data);
    },
  },
  getters: {
    [getters.GET_SELECTED_MARKETS](state, moduleGetters, rootState, rootGetters) {
      const selectedOdds = rootGetters[`LiveStore/SELECTED_ODDS`];

      return Object.entries(selectedOdds).map(([marketRowId, oddsId]) => {
        const marketRowsOdds = moduleGetters[getters.GET_ODDS_BY_MARKET_ROW_ID](marketRowId);

        return marketRowsOdds?.find((marketRowOdd) => oddsId.includes(marketRowOdd.id));
      });
    },
    [getters.GET_MARKET_BY_ID](state) {
      return (marketId) => state.markets[marketId] || null;
    },
    // eslint-disable-next-line
    [getters.GET_MARKET_IDS_BY_MARKET_GROUP_ID_AND_MATCH_ID](state) {
      return (marketGroupId, matchId) => Object.values(state.markets)
        .filter((market) => market.matchId === matchId && market.marketGroupIds.includes(marketGroupId))
        .sort((a, b) => {
          const orderDiff = a.displayOrder - b.displayOrder;

          if (orderDiff === 0) {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
          }
          return Math.sign(orderDiff);
        })
        .map((market) => market.id);
    },
    [getters.GET_MARKET_BY_MARKET_ROW_ID](state) {
      return (marketRowId) => {
        const marketId = state.marketsMapping[marketRowId];

        return state.markets[marketId] || null;
      };
    },
    [getters.GET_ODDS_BY_MARKET_ROW_ID](state, moduleGetters, rootState, rootGetters) {
      return (marketRowId) => {
        if (!marketRowId) return null;
        const marketId = state.marketsMapping[marketRowId];
        const market = state.markets[marketId];

        if (!market) return null;

        const odds = rootGetters[`LiveStore/ODDS_GET_BY_MARKET_ID`](marketId);

        return Object.values(odds);
      };
    },
    [getters.GET_MATCH_ID_BY_MARKET_ID](state) {
      return (marketId) => {
        const mappedMarket = state.marketsMapping[marketId];
        const market = mappedMarket && state.markets[mappedMarket];

        return market?.matchId;
      };
    },
    [getters.IS_SAME_EVENT_BY_MARKET_IDS](state, moduleGetters) {
      return (marketIdA, marketIdB) => {
        const { eventId: eventIdA = `` } = moduleGetters[getters.GET_MARKET_BY_MARKET_ROW_ID](marketIdA) || {};
        const { eventId: eventIdB = `` } = moduleGetters[getters.GET_MARKET_BY_MARKET_ROW_ID](marketIdB) || {};

        return eventIdA === eventIdB;
      };
    },
    [getters.GET_COMBINABLE_MARKETS](state, moduleGetters, rootState, rootGetters) {
      return (matchId) => {
        const marketIds = rootGetters[`LiveStore/GET_MATCH_MARKET_IDS`](matchId);
        const markets = marketIds
          .map((id) => moduleGetters[getters.GET_MARKET_BY_ID](id) || {});

        const combinableMarkets = {};

        const allMarketRows = markets.reduce((acc, market) => {
          if (Array.isArray(market.marketRows)) {
            acc.push(...market.marketRows);
          }
          return acc;
        }, []);

        allMarketRows.forEach((marketRowIdA) => {
          const foundMarketRowIdB = allMarketRows.find(
            (marketRowIdB) => !moduleGetters[getters.CHECK_MARKET_SUPPORTIVENESS_BY_IDS](marketRowIdA, marketRowIdB) && marketRowIdA !== marketRowIdB,
          );

          if (foundMarketRowIdB && marketRowIdA !== foundMarketRowIdB) {
            const combinableMarket = moduleGetters[getters.GET_MARKET_BY_MARKET_ROW_ID](marketRowIdA);

            combinableMarkets[combinableMarket.id] = combinableMarket;
          }
        });

        return Object.values(combinableMarkets);
      };
    },
    [getters.GET_COMBINABLE_MARKETS_BY_MATCH_ID](state, moduleGetters) {
      return (matchId) => {
        const out = moduleGetters[getters.GET_COMBINABLE_MARKETS](matchId)
          .filter((market) => {
            const marketIsSupportive = market.marketRows
              .reduce((acc, row) => moduleGetters[getters.IS_MARKET_SUPPORTIVE_TO_SELECTED](row, false) || acc, false);

            return !marketIsSupportive;
          })
          .map(({ id }) => id);

        return out;
      };
    },
    [getters.IS_MARKET_SUPPORTIVE_TO_SELECTED](state, moduleGetters, rootState, rootGetters) {
      return (marketRowId, strict = true) => {
        const selectedOdds = rootGetters[`LiveStore/SELECTED_ODDS`];
        const selectedMarketRowIds = Object.keys(selectedOdds);
        const market = moduleGetters[getters.GET_MARKET_BY_MARKET_ROW_ID](marketRowId);

        const marketIsSelected = market.marketRows.some((marketRow) => selectedMarketRowIds.includes(marketRow));

        if (!strict && marketIsSelected) return false;
        if (selectedMarketRowIds.length === 0) return false;
        if (selectedMarketRowIds.includes(marketRowId)) return false;

        return selectedMarketRowIds.some((selectedMarketRowId) => moduleGetters[getters.CHECK_MARKET_SUPPORTIVENESS_BY_IDS](marketRowId, selectedMarketRowId));
      };
    },
    [getters.CHECK_MARKET_SUPPORTIVENESS_BY_IDS](state, moduleGetters) {
      return (marketRowIdA, marketRowIdB) => {
        const marketA = moduleGetters[getters.GET_MARKET_BY_MARKET_ROW_ID](marketRowIdA);
        const marketB = moduleGetters[getters.GET_MARKET_BY_MARKET_ROW_ID](marketRowIdB);

        if (!marketA || !marketB) return false;
        const marketsAreFromSameEvent = moduleGetters[getters.IS_SAME_EVENT_BY_MARKET_IDS](marketRowIdA, marketRowIdB);

        if (marketsAreFromSameEvent) {
          const {
            supportGroup: { markets: supportGroupsMarketA },
            supportGroupEx: { markets: supportGroupsExMarketA },
          } = marketA;

          const {
            supportGroup: { markets: supportGroupsMarketB },
            supportGroupEx: { markets: supportGroupsExMarketB },
          } = marketB;

          const supportGroupA = supportGroupsMarketA[marketRowIdA];
          const supportGroupExA = supportGroupsExMarketA[marketRowIdA];
          const supportGroupB = supportGroupsMarketB[marketRowIdB];
          const supportGroupExB = supportGroupsExMarketB[marketRowIdB];

          // not green group, no combination available
          if (supportGroupB === 0 || supportGroupA === 0) return true;

          if (!checkIfSameGroup(supportGroupB, supportGroupA)) return true;

          return checkIfSameGroup(supportGroupExB, supportGroupExA);
        }

        return false;
      };
    },
  },
};

export default liveMarketStore;
