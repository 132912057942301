import { getLanguage } from '../../config/locale';

// get language only once, since locale changes are happening with page refresh
const lang = getLanguage();

const BY_NAME = ({ names: namesA }, { names: namesB }) => {
  const name1 = namesA.toLowerCase();
  const name2 = namesB.toLowerCase();

  // localeCompare works fairly well in IE11-,
  // however the locale options is only supported in IE11+
  return name1.localeCompare(name2, lang);
};

const BY_START_DATE = (a, b) => {
  const date1 = new Date(a.startDate);
  const date2 = new Date(b.startDate);

  if (date1 < date2) return -1;
  if (date1 > date2) return 1;
  return 0;
};

export const BY_DISPLAY_ORDER = (a, b) => a.displayOrder - b.displayOrder;

export function getMatchComparator(sortByMatchName, sortByMatchStart) {
  if (sortByMatchName) return BY_NAME;
  if (sortByMatchStart) return BY_START_DATE;
  return BY_DISPLAY_ORDER;
}
