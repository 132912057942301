export const AUTH_OPERATION_PENDING = `AUTH_OPERATION_PENDING`;
export const AUTH_OPERATION_SUCCESS = `AUTH_OPERATION_SUCCESS`;
export const AUTH_OPERATION_ERROR = `AUTH_OPERATION_ERROR`;
export const AUTH_SET_ERROR_MESSAGES = `AUTH_SET_ERROR_MESSAGES`;
export const REDIRECT_USER_BY_STATUS = `REDIRECT_TO_REGISTRATION`;
export const REDIRECT_USER_BY_ABP = `REDIRECT_TO_REGISTRATION_BY_ABP`;
export const AUTH_NOTIFICATION_TOKEN_SUCCESS = `AUTH_NOTIFICATION_TOKEN_SUCCESS`;
export const DOING_LOGOUT = `DOING_LOGOUT`;
export const DOING_LOGOUT_FINISHED = `DOING_LOGOUT_FINISHED`;
export const SSO = `SSO`;
