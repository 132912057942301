import { getLocale } from '@/config/locale';
import {
  LOCALE_CS_CZ,
  LOCALE_EN_US,
  LOCALE_PL_PL,
  LOCALE_RO_RO,
  LOCALE_SK_SK,
} from '../enums/Locale.enums';

// more info about pluralization
// http://docs.translatehouse.org/projects/localization-guide/en/latest/index.html

/**
 * Slavic 1
 * Plurals: 3 {one, few, other}
 * For languages: cz, sk
 * @param count {number}
 * @returns {number} index for pluralization string array
 */
const pluralRuleSlavic1 = (count) => {
  if (count === 1) return 0;
  return count >= 2 && count <= 4 ? 1 : 2;
};

/**
 * Anglo Saxon 1
 * Plurals: 2 {one, other}
 * For languages: en, de, nl, sv, da, no, nn, nb, fo, es, pt, it, bg, el, fi, et, he, eo, hu, tr
 * @param count {number}
 * @returns {number} index for pluralization string array
 */
const pluralRuleAngloSaxon1 = (count) => (count !== 1 ? 1 : 0);

/**
 * Romanian
 * Plurals: 3 {one, few, other}
 * For languages: ro
 * @param count {number}
 * @returns {number} index for pluralization string array
 */
const pluralRuleRomanian = (count) => {
  if (count === 1) return 0;
  return (count === 0 || (count % 100 > 0 && count % 100 < 20)) ? 1 : 2;
};

/**
 * Polish
 * Plurals: 3 {one, few, other}
 * For languages: pl
 * @param count {number}
 * @returns {number} index for pluralization string array
 */
const pluralRulePolish = (count) => {
  if (count === 1) return 0;
  return count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20) ? 1 : 2;
};

/**
 *
 * @param count {number}
 * @param translatedMessage {String}
 * @param locale {String}
 * @returns {String}
 */
export function pluralize(count, translatedMessage, locale = getLocale()) {
  let indexOfPluralization;

  const valuesForPluralization = translatedMessage.split(`|`);

  if (!count < 0 || !translatedMessage || !locale) {
    return ``;
  }
  if (valuesForPluralization.length === 0
          || (valuesForPluralization.length === 1 && !valuesForPluralization[0])) {
    return translatedMessage;
  }

  switch (locale) {
    case LOCALE_EN_US:
      indexOfPluralization = pluralRuleAngloSaxon1(count);
      break;
    case LOCALE_RO_RO:
      indexOfPluralization = pluralRuleRomanian(count);
      break;
    case LOCALE_PL_PL:
      indexOfPluralization = pluralRulePolish(count);
      break;
    case LOCALE_CS_CZ:
    case LOCALE_SK_SK:
    default:
      indexOfPluralization = pluralRuleSlavic1(count);
      break;
  }
  return valuesForPluralization[indexOfPluralization] || translatedMessage;
}
