import Vue from 'vue';
import {
  ADD_DISCONNECTION_MESSAGE,
  DELETE_DISCONNECTION_MESSAGE,
} from '@/stores/WebsocketStore/constants';

export default {
  [ADD_DISCONNECTION_MESSAGE](state, message) {
    state.disconnectionMessage = message;
  },
  [DELETE_DISCONNECTION_MESSAGE](state) {
    Vue.set(state, `disconnectionMessage`, {});
  },
};
