import store from '@/config/store';
import * as userStoreGetters from '@/stores/UserStore/getters';
import { loggerErrors } from '@/globals/enums/Logger.enums';

export const getKibanaFormatTimestamp = () => {
  const date = new Date();
  const monthNames = [
    `January`,
    `February`,
    `March`,
    `April`,
    `May`,
    `June`,
    `July`,
    `August`,
    `September`,
    `October`,
    `November`,
    `December`,
  ];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();

  let dayPostfix;

  switch (day % 10) {
    case 1: dayPostfix = `st`; break;
    case 2: dayPostfix = `nd`; break;
    case 3: dayPostfix = `rd`; break;
    default: dayPostfix = `th`;
  }
  const year = date.getFullYear();
  const completeTime = date.toTimeString().split(` `)[0];

  return `${month} ${day}${dayPostfix} ${year}, ${completeTime}`;
};

export const getUserID = () => {
  const userInfo = store.getters[`UserStore/${userStoreGetters.GET_USER_INFO}`];

  if (!userInfo.id) {
    return loggerErrors.USER_NOT_LOGGED;
  }
  return userInfo.id;
};
