export const IS_USER_LOGGED_IN = `IS_USER_LOGGED_IN`;
export const IS_DOING_LOGOUT = `IS_DOING_LOGOUT`;
export const GET_USER_INFO = `GET_USER_INFO`;
export const USER_GET_CLIENT_ID = `USER_GET_CLIENT_ID`;
export const USER_GET_STAKE_SETTINGS = `USER_GET_STAKE_SETTINGS`;
export const HAS_CHAT_PERMISSIONS = `HAS_CHAT_PERMISSIONS`;
export const USER_CAN_PLACE_BET = `USER_CAN_PLACE_BET`;
export const HAS_FINISHED_REGISTRATION = `HAS_FINISHED_REGISTRATION`;
export const GET_USER_ID = `GET_USER_ID`;
export const GET_NOTIFIACTION_TOKEN = `GET_NOTIFICATION_TOKEN`;
export const IS_SSO = `IS_SSO`;
