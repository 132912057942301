export const OFFER_FILTER_TYPE_ALL = `all`;
export const OFFER_FILTER_TYPE_LIVE = `live`;
export const OFFER_FILTER_TYPE_UNTIL_1_HOUR = `until1Hour`;

const hasStarted = (now, startDate) => new Date(startDate).getTime() > now.getTime();

const untilFilter = (hours) => (now) => ({ startDate, mirrorReflex }) => mirrorReflex
  && hasStarted(now, startDate)
  && ((new Date(startDate).getTime() - now.getTime()) <= hours * 3600 * 1000);

export const offerFiltersDefinition = [
  {
    offerFilterType: OFFER_FILTER_TYPE_ALL,
    offerFilter: () => () => true,
  },
  {
    offerFilterType: OFFER_FILTER_TYPE_LIVE,
    offerFilter: () => ({ mirrorReflex }) => !mirrorReflex,
  },
  {
    offerFilterType: OFFER_FILTER_TYPE_UNTIL_1_HOUR,
    offerFilter: untilFilter(1),
  },
];
