import LiveLeagueStore from './LiveLeagueStore';
import LiveMarketGroupStore from './LiveMarketGroupStore';
import LiveMarketStore from './LiveMarketStore';
import LiveMatchStore from './LiveMatchStore';
import LiveMinuteByMinuteStatsStore from './LiveMinuteByMinuteStatsStore';
import LiveScoreboardStore from './LiveScoreboardStore';
import LiveSportStore from './LiveSportStore';
import LiveMatchOverviewStore from './LiveMatchOverviewStore';
import LiveMiniScoreboardStore from './LiveMiniScoreboardStore';
import LiveOddsStore from './LiveOddsStore';
import LiveTopMarketStore from './LiveTopMarketStore';
import LiveSportMenuStore from './ui/LiveSportMenuStore';

export const types = {
  FETCH_LIVE_PENDING: `FETCH_LIVE_PENDING`,
  FETCH_LIVE_DONE: `FETCH_LIVE_DONE`,
  FETCH_LIVE_ERROR: `FETCH_LIVE_ERROR`,
  FETCH_MATCH_DETAIL_PENDING: `FETCH_MATCH_DETAIL_PENDING`,
  FETCH_MATCH_DETAIL_DONE: `FETCH_MATCH_DETAIL_DONE`,
  FETCH_MATCH_DETAIL_ERROR: `FETCH_MATCH_DETAIL_ERROR`,
};

export const actions = {
  FETCH_LIVE_PENDING: `fetchLivePending`,
  FETCH_LIVE_DONE: `fetchLiveSuccess`,
  FETCH_LIVE_ERROR: `fetchLiveError`,
  FETCH_MATCH_DETAIL_PENDING: `fetchMatchDetailPending`,
  FETCH_MATCH_DETAIL_DONE: `fetchMatchDetailDone`,
  FETCH_MATCH_DETAIL_ERROR: `FetchMatchDetailError`,
};

const liveStore = {
  namespaced: true,
  modules: {
    LiveOddsStore,
    LiveSportStore,
    LiveLeagueStore,
    LiveMatchStore,
    LiveMarketStore,
    LiveTopMarketStore,
    LiveMarketGroupStore,
    LiveScoreboardStore,
    LiveMatchOverviewStore,
    LiveMiniScoreboardStore,
    LiveMinuteByMinuteStatsStore,
    LiveSportMenuStore,
  },
  state: {
    liveFetch: {
      isFirstLoading: true,
      isLoading: true,
      isError: false,
    },
    matchDetailFetch: {
      isLoading: false,
      isSilentLoading: false,
      isError: false,
    },
  },
  mutations: {
    [types.FETCH_LIVE_PENDING](state) {
      state.liveFetch.isError = false;
      state.liveFetch.isLoading = true;
    },
    [types.FETCH_LIVE_ERROR](state) {
      state.liveFetch.isLoading = false;
      state.liveFetch.isError = true;
    },
    [types.FETCH_LIVE_DONE](state) {
      state.liveFetch.isLoading = false;
      state.liveFetch.isFirstLoading = false;
    },
    [types.FETCH_MATCH_DETAIL_PENDING](state, silent = false) {
      state.matchDetailFetch.isLoading = true;
      state.matchDetailFetch.isSilentLoading = silent;
      state.matchDetailFetch.isError = false;
    },
    [types.FETCH_MATCH_DETAIL_ERROR](state) {
      state.matchDetailFetch.isLoading = false;
      state.matchDetailFetch.isSilentLoading = false;
      state.matchDetailFetch.isError = true;
    },
    [types.FETCH_MATCH_DETAIL_DONE](state) {
      state.matchDetailFetch.isLoading = false;
      state.matchDetailFetch.isSilentLoading = false;
      state.matchDetailFetch.isError = false;
    },
  },
  actions: {
    [actions.FETCH_LIVE_PENDING]({ commit }) {
      commit(types.FETCH_LIVE_PENDING);
    },
    [actions.FETCH_LIVE_ERROR]({ commit }) {
      commit(types.FETCH_LIVE_ERROR);
    },
    [actions.FETCH_LIVE_DONE]({ commit }) {
      commit(types.FETCH_LIVE_DONE);
    },
    [actions.FETCH_MATCH_DETAIL_PENDING]({ commit }, payload) {
      commit(types.FETCH_MATCH_DETAIL_PENDING, payload);
    },
    [actions.FETCH_MATCH_DETAIL_ERROR]({ commit }) {
      commit(types.FETCH_MATCH_DETAIL_ERROR);
    },
    [actions.FETCH_MATCH_DETAIL_DONE]({ commit }) {
      commit(types.FETCH_MATCH_DETAIL_DONE);
    },
  },
};

export default liveStore;
