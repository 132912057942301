import { LOGIN_STATUS_LOGGED_IN, LOGIN_STATUS_LOGGED_OUT } from '@/globals/enums/enums';
import {
  USER_STATUS_LIMITED_TEMPORARY, USER_STATUS_PREREGISTERED,
  USER_STATUS_RETAIL,
  USER_STATUS_TEMPORARY,
} from '@/globals/enums/userStatus';
import {
  USER_ABP_PREREGISTERED, USER_ABP_TEMPORARY, USER_ABP_INSHOP,
} from '@/globals/enums/userAccountBusinessPhase';
import { FEATURE_PHONE_VERIFICATION, FEATURE_REGISTER_REDIRECT } from '@/config/constants/features';
import safeGet from 'lodash/get';
import { APP_CONF_GET_CONF_VALUE, IS_FEATURE_SUPPORTED } from '@/stores/AppConfigurationStore/constants';
import PermissionService from '@/services/PermissionService';
import { AlertMessage } from '@/factories/AlertMessage';
import { BET_PERMISSION, CHAT_PERMISSION, REGISTERED_PERMISSION } from '@/globals/enums/UserPermissions';
import { SITE } from '@/config/constants';
import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

export default {
  namespaced: true,
  state: {
    userInfo: {},
    cbEnabled: false,
    errorMessages: [],
    isLoading: false,
    isDoingLogout: false,
    sso: false,
    isError: false,
    loginStatus: LOGIN_STATUS_LOGGED_OUT,
    notificationToken: null,
  },
  mutations: {
    [mutations.AUTH_OPERATION_PENDING](state) {
      state.errorMessages = [];
      state.isLoading = true;
      state.isError = false;
    },
    [mutations.DOING_LOGOUT](state) {
      state.isDoingLogout = true;
    },
    [mutations.DOING_LOGOUT_FINISHED](state) {
      state.isDoingLogout = false;
    },
    [mutations.SSO](state, active) {
      state.sso = active;
    },
    [mutations.AUTH_OPERATION_SUCCESS](state, { userInfo, cbEnabled, status: loginStatus }) {
      state.userInfo = userInfo;
      state.cbEnabled = cbEnabled ?? false;
      // legacy reasons - client status can be in field accountBusinessPhase or clientStatus
      if (userInfo && userInfo.accountBusinessPhase) {
        state.userInfo.clientStatus = userInfo.accountBusinessPhase;
      }
      state.loginStatus = loginStatus;
      state.errorMessages = [];
      state.isLoading = false;
      state.isError = false;
    },
    [mutations.AUTH_OPERATION_ERROR](state, { response: errorResponse }) {
      let errorMessages = [];

      if (errorResponse && errorResponse.data) {
        errorMessages = errorResponse.data.messages || [];
        errorMessages.code = errorResponse.data.code;
      }
      state.errorMessages = errorMessages;
      state.isError = true;
      state.isLoading = false;
    },
    [mutations.AUTH_SET_ERROR_MESSAGES](state, errors) {
      state.errorMessages = errors;
    },
    [mutations.AUTH_NOTIFICATION_TOKEN_SUCCESS](state, token) {
      state.notificationToken = token;
    },
  },
  actions: {
    [actions.AUTH_OPERATION_PENDING]({ commit }) {
      commit(mutations.AUTH_OPERATION_PENDING);
    },
    [actions.DOING_LOGOUT]({ commit }) {
      commit(mutations.DOING_LOGOUT);
    },
    [actions.DOING_LOGOUT_FINISHED]({ commit }) {
      commit(mutations.DOING_LOGOUT_FINISHED);
    },
    [actions.SSO]({ commit }, active) {
      commit(mutations.SSO, active);
    },
    [actions.AUTH_OPERATION_SUCCESS]({ commit, dispatch, rootGetters }, data) {
      if (rootGetters[`AppConfigurationStore/${IS_FEATURE_SUPPORTED}`](FEATURE_REGISTER_REDIRECT) && data.userInfo) {
        // TODO 2019-10-03: (RND-36157) unify clientStatus and accountBusinessPhase key
        const { clientStatus, accountBusinessPhase } = data.userInfo;

        if (clientStatus === USER_STATUS_PREREGISTERED || clientStatus === USER_STATUS_RETAIL) {
          dispatch(actions.REDIRECT_USER_BY_STATUS, clientStatus);
        }

        if (accountBusinessPhase === USER_ABP_PREREGISTERED || accountBusinessPhase === USER_ABP_INSHOP) {
          dispatch(actions.REDIRECT_USER_BY_ABP, accountBusinessPhase);
        }
      }
      commit(mutations.AUTH_OPERATION_SUCCESS, data);
      return data;
    },
    [actions.REDIRECT_USER_BY_STATUS]({ state, rootState }, status) {
      status = status || state.userInfo.clientStatus;
      let redirectUrl;

      switch (status) {
        case USER_STATUS_RETAIL:
          redirectUrl = rootState.AppConfigurationStore.configuration.statusRetailRedirectUrl;
          break;
        case USER_STATUS_PREREGISTERED:
          redirectUrl = rootState.AppConfigurationStore.configuration.statusPreregisterRedirectUrl;
          break;
        case USER_STATUS_LIMITED_TEMPORARY:
          redirectUrl = rootState.AppConfigurationStore.configuration.statusLimitedTemporaryRedirectUrl;
          break;
        case USER_STATUS_TEMPORARY:
          redirectUrl = rootState.AppConfigurationStore.configuration.statusTemporaryRedirectUrl;
          break;
        default:
          return;
      }
      if (redirectUrl) {
        window.location = redirectUrl;
      } else {
        // eslint-disable-next-line no-console
        console.error(`Configuration value for status ${status} is not set, user was not redirected`);
      }
    },
    // FIXME Copy of action `REDIRECT_USER_BY_STATUS` - refactor
    [actions.REDIRECT_USER_BY_ABP]({ state, rootState }, accountBusinessPhase) {
      accountBusinessPhase = accountBusinessPhase || state.userInfo.accountBusinessPhase;
      let redirectUrl;

      switch (accountBusinessPhase) {
        case USER_ABP_INSHOP:
          redirectUrl = rootState.AppConfigurationStore.configuration.statusRetailRedirectUrl;
          break;
        case USER_ABP_PREREGISTERED:
          redirectUrl = rootState.AppConfigurationStore.configuration.statusPreregisterRedirectUrl;
          break;
        case USER_ABP_TEMPORARY:
          redirectUrl = rootState.AppConfigurationStore.configuration.statusTemporaryRedirectUrl;
          break;
        default:
          return;
      }
      if (redirectUrl) {
        window.location = redirectUrl;
      } else {
        // eslint-disable-next-line no-console
        console.error(`Configuration value for status ${accountBusinessPhase} is not set, user was not redirected`);
      }
    },
    [actions.AUTH_OPERATION_ERROR]({ commit }, err) {
      commit(mutations.AUTH_OPERATION_ERROR, err);
    },
    [actions.AUTH_SET_ERROR_MESSAGES]({ commit }, errorList) {
      const translatedErrorList = errorList.map((error) => AlertMessage(error));

      commit(mutations.AUTH_SET_ERROR_MESSAGES, translatedErrorList);
    },
    [actions.AUTH_NOTIFICATION_TOKEN_SUCCESS]({ commit, rootGetters }, token) {
      if (rootGetters[`AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`](`inAppNotificationsEnabled`)) {
        commit(mutations.AUTH_NOTIFICATION_TOKEN_SUCCESS, token);
      }
    },
  },
  getters: {
    [getters.IS_USER_LOGGED_IN](state) {
      return state.loginStatus === LOGIN_STATUS_LOGGED_IN;
    },
    [getters.IS_DOING_LOGOUT](state) {
      return state.isDoingLogout;
    },
    [getters.IS_SSO](state) {
      return state.sso;
    },
    [getters.GET_USER_INFO](state) {
      return state.userInfo || {};
    },
    [getters.HAS_CHAT_PERMISSIONS](state, moduleGetters, rootState, rootGetters) {
      const userInfo = state.userInfo || {};

      return (!rootGetters[`AppConfigurationStore/${IS_FEATURE_SUPPORTED}`](FEATURE_PHONE_VERIFICATION)
        || userInfo.mobilePhoneVerified === true)
          && PermissionService.getUserPermission(userInfo.clientStatus, CHAT_PERMISSION, SITE);
    },
    [getters.HAS_FINISHED_REGISTRATION](state) {
      const userInfo = state.userInfo || {};

      return PermissionService.getUserPermission(userInfo.clientStatus, REGISTERED_PERMISSION, SITE);
    },
    [getters.USER_CAN_PLACE_BET](state) {
      const userInfo = state.userInfo || {};

      return PermissionService.getUserPermission(userInfo.clientStatus, BET_PERMISSION, SITE);
    },
    [getters.USER_GET_CLIENT_ID](state) {
      return safeGet(state, `userInfo.clientId`, null);
    },
    [getters.USER_GET_STAKE_SETTINGS](state, moduleGetters, rootState, rootGetters) {
      // eslint-disable-next-line max-len
      const defaultValues = rootGetters[`AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`](`ticket.quickStakes`, []);

      return Object
        .values(
          safeGet(
            state,
            `userInfo.stakeButtons`,
            defaultValues,
          ),
        ).map((val, index) => val || defaultValues[index]);
    },
    [getters.GET_USER_ID](state) {
      return state.id || null;
    },
    [getters.GET_NOTIFIACTION_TOKEN](state) {
      return state.notificationToken || null;
    },
  },
};
