import { APP_CONF_GET_CONF_VALUE } from '@/stores/AppConfigurationStore/constants';
import {
  LEAGUE_SET_COLLAPSE_STATE_BY_MATCH_ID,
  ADD_AND_LINK_LEAGUE_LIST,
  $ADD_LEAGUE_LIST,
  TOGGLE_LEAGUE,
  $TOGGLE_LEAGUE,
  TOGGLE_LEAGUES_BY_SPORT,
  SET_INITIAL_LEAGUE_COLLAPSE_FOR_ALL_SPORTS,
  SET_INITIAL_LEAGUE_COLLAPSE_FOR_INDIVIDUAL_SPORTS,
  $SET_COLLAPSE_LEAGUES_BY_SPORT,
  SET_LEAGUE_COLLAPSE_STATE,
  $SET_LEAGUE_COLLAPSE_STATE,
} from '@/stores/LiveLeagueStore/constants';

import {
  getters as sportGetters,
  $TOGGLE_COLLAPSE_STATE,
  $LINK_LEAGUES_TO_SPORT,
} from "@/stores/LiveSportStore";

import {
  getters as matchGetters,
} from "@/stores/LiveMatchStore";

import { DefaultMaximumVisibleMatches } from '@/globals/enums/enums';

export default {
  [LEAGUE_SET_COLLAPSE_STATE_BY_MATCH_ID]: () => true,
  [ADD_AND_LINK_LEAGUE_LIST]({ commit }, leagues) {
    commit($ADD_LEAGUE_LIST, leagues);
    commit($LINK_LEAGUES_TO_SPORT, leagues);
  },
  [TOGGLE_LEAGUE]({ commit }, payload) {
    commit($TOGGLE_LEAGUE, { ...payload });
  },
  [SET_LEAGUE_COLLAPSE_STATE]({ commit }, payload) {
    commit($SET_LEAGUE_COLLAPSE_STATE, { ...payload });
  },
  [SET_INITIAL_LEAGUE_COLLAPSE_FOR_ALL_SPORTS]({ state, commit, getters, rootGetters }) {
    const maxVisibleMatches = rootGetters[`AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`](`openedMatchesAll`)
        || DefaultMaximumVisibleMatches;

    const sportIds = getters[sportGetters.SPORTS_GET_ALL].map((sport) => sport.id);
    const openedSportIds = [];
    const orderedLeaguesBySportIds = Object
      .values(state.leagues)
      .sort((leagueA, leagueB) => sportIds.indexOf(leagueA.sportId) - sportIds.indexOf(leagueB.sportId));

    let openedMatchCount = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const league of orderedLeaguesBySportIds) {
      const isCollapsed = openedMatchCount >= maxVisibleMatches;

      if (!openedSportIds.includes(league.sportId)) {
        commit($TOGGLE_COLLAPSE_STATE,
          { id: league.sportId, category: `generic`, toggleState: isCollapsed },
        );
        commit($TOGGLE_LEAGUE,
          { id: `FAVORITES.${league.sportId}`, category: `generic`, toggleState: isCollapsed },
        );
        openedSportIds.push(league.sportId);
      }
      commit($TOGGLE_LEAGUE, { id: league.id, category: `generic`, toggleState: isCollapsed });
      openedMatchCount += getters[matchGetters.GET_FILTERED_MATCHES_BY_LEAGUE](league.id).length;
    }
    return Promise.resolve();
  },
  [SET_INITIAL_LEAGUE_COLLAPSE_FOR_INDIVIDUAL_SPORTS]({ state, commit, getters, rootGetters }) {
    const maxVisibleMatches = rootGetters[`AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`](`openedMatchesSports`)
      || DefaultMaximumVisibleMatches;
    const sports = getters[sportGetters.SPORTS_GET_ALL];

    sports.forEach((sport) => {
      let openedMatchCount = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const leagueId of sport.leagues) {
        if (openedMatchCount >= maxVisibleMatches) break;

        if (state.collapsed.touched[leagueId] !== true) {
          commit($TOGGLE_LEAGUE, { id: leagueId, category: `single` });
        }

        openedMatchCount += state.leagues[leagueId].matches.length;
      }
      commit($TOGGLE_LEAGUE, { id: `FAVORITES.${sport.id}`, category: `single` });

      commit($TOGGLE_COLLAPSE_STATE, { id: sport.id, category: `favorites` });
      commit($TOGGLE_LEAGUE, { id: `FAVORITES.${sport.id}`, category: `favorites` });
    });
    return Promise.resolve();
  },
  [TOGGLE_LEAGUES_BY_SPORT]({ commit, getters }, payload) {
    const newCollapseState = getters.IS_ALL_LEAGUES_OPEN(payload);

    commit($SET_COLLAPSE_LEAGUES_BY_SPORT, {
      ...payload,
      value: newCollapseState,
    });
  },
};
