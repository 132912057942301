import safeGet from 'lodash/get';
import { APP_CONF_GET_CONF_VALUE, IS_FEATURE_SUPPORTED } from '@/stores/AppConfigurationStore/constants';

export default {
  [APP_CONF_GET_CONF_VALUE]({ configuration }) {
    return (path, defaultValue = null) => safeGet(configuration, path, defaultValue);
  },
  [IS_FEATURE_SUPPORTED]({ configuration }) {
    return (featureName) => safeGet(configuration, `featureToggle`, []).includes(featureName);
  },
};
