import mutations from '@/stores/WebsocketStore/mutations';

export const initialState = {
  disconnectionMessage: {},
};

const WebsocketStore = {
  namespaced: true,
  state: { ...initialState },
  mutations,
};

export default WebsocketStore;
