// mutations
export const $DELETE_LEAGUE = `$DELETE_LEAGUE`;
export const $LINK_MATCH_TO_LEAGUE = `$LINK_MATCH_TO_LEAGUE`;
export const $LINK_MATCH_LIST_TO_LEAGUE = `$LINK_MATCH_LIST_TO_LEAGUE`;
export const $UNLINK_MATCH_FROM_LEAGUE = `$UNLINK_MATCH_FROM_LEAGUE`;
export const $ADD_LEAGUE_LIST = `$ADD_LEAGUE_LIST`;
export const $UPDATE_LEAGUE_COLLAPSE_STATES = `$UPDATE_LEAGUE_COLLAPSE_STATES`;
export const $TOGGLE_LEAGUE = `$TOGGLE_LEAGUE`;
export const $SET_LEAGUE_COLLAPSE_STATE = `$SET_LEAGUE_COLLAPSE_STATE`;
export const $SET_COLLAPSE_LEAGUES_BY_SPORT = `$SET_COLLAPSE_LEAGUES_BY_SPORT`;

// actions
export const LEAGUE_SET_COLLAPSE_STATE_BY_MATCH_ID = `LEAGUE_SET_COLLAPSE_STATE_BY_MATCH_ID`;
export const ADD_AND_LINK_LEAGUE_LIST = `ADD_AND_LINK_LEAGUE_LIST`;
export const TOGGLE_LEAGUE = `TOGGLE_LEAGUE`;
export const SET_LEAGUE_COLLAPSE_STATE = `SET_LEAGUE_COLLAPSE_STATE`;
export const TOGGLE_LEAGUES_BY_SPORT = `TOGGLE_LEAGUES_BY_SPORT`;
export const SET_INITIAL_LEAGUE_COLLAPSE_FOR_ALL_SPORTS = `SET_INITIAL_LEAGUE_COLLAPSE_FOR_ALL_SPORTS`;
export const SET_INITIAL_LEAGUE_COLLAPSE_FOR_INDIVIDUAL_SPORTS = `SET_INITIAL_LEAGUE_COLLAPSE_FOR_INDIVIDUAL_SPORTS`;

// getters
export const GET_FILTERED_LEAGUES_BY_SPORT_ID = `GET_FILTERED_LEAGUES_BY_SPORT_ID`;
export const GET_FILTERED_LEAGUE_IDS_BY_SPORT = `GET_FILTERED_LEAGUE_IDS_BY_SPORT`;
export const GET_LEAGUE_BY_ID = `getLeagueById`;
export const LEAGUES_GET_BY_SPORT_LIMITED = `LEAGUES_GET_BY_SPORT_LIMITED`;
export const IS_ALL_LEAGUES_OPEN = `IS_ALL_LEAGUES_OPEN`;
export const IS_LEAGUE_COLLAPSED = `IS_LEAGUE_COLLAPSED`;
export const GET_LIMITED_FILTERED_LEAGUE_IDS_BY_SPORT = `GET_LIMITED_FILTERED_LEAGUE_IDS_BY_SPORT`;
