import { obtainIpAuthorizationToken } from '@/services/http/StreamAuthorizationHandler';
import * as mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

const REQUEST_STATE = {
  INITIAL: `INITIAL`,
  PENDING: `PENDING`,
  SUCCESS: `SUCCESS`,
  UNAUTHORIZED: `UNAUTHORIZED`,
  ERROR: `ERROR`,
};

export default {
  namespaced: true,
  state: {
    requestState: REQUEST_STATE.INITIAL,
    token: null,
  },
  mutations: {
    [mutations.IP_AUTH_SET_REQUEST_STATE](state, requestState) {
      state.requestState = requestState;
    },
    [mutations.SET_AUTH_TOKEN](state, token) {
      state.token = token;
    },
  },
  actions: {
    async [actions.IP_AUTH_REQUEST]({ commit }) {
      commit(mutations.IP_AUTH_SET_REQUEST_STATE, REQUEST_STATE.PENDING);
      try {
        const token = await obtainIpAuthorizationToken();

        commit(mutations.IP_AUTH_SET_REQUEST_STATE, token ? REQUEST_STATE.SUCCESS : REQUEST_STATE.UNAUTHORIZED);
        commit(mutations.SET_AUTH_TOKEN, token ?? null);
      } catch (error) {
        commit(mutations.IP_AUTH_SET_REQUEST_STATE, REQUEST_STATE.ERROR);
      }
    },
  },
  getters: {
    [getters.IS_AUTHORIZED](state) {
      return state.requestState === REQUEST_STATE.SUCCESS;
    },
    [getters.IS_PENDING](state) {
      return state.requestState === REQUEST_STATE.PENDING;
    },
    [getters.HAS_ERROR](state) {
      return state.requestState === REQUEST_STATE.ERROR;
    },
    [getters.GET_TOKEN](state) {
      return state.token;
    },
  },
};
