import Vue from 'vue';

export const mutationNames = {
  ADD_MARKET_TYPE_ID: `ADD_MARKET_TYPE_ID`,
  SET_MARKET_TYPES: `SET_MARKET_TYPES`,
  REMOVE_MARKET_TYPE_ID: `REMOVE_MARKET_TYPE_ID`,
};

export const actionNames = {
  ADD_MARKET_TYPE_ID: `ADD_MARKET_TYPE_ID`,
  SET_MARKET_TYPES: `SET_MARKET_TYPES`,
  REMOVE_MARKET_TYPE_ID: `REMOVE_MARKET_TYPE_ID`,
};

export const getterNames = {
  IS_FAVORITE_MARKET_TYPE: `IS_FAVORITE_MARKET_TYPE`,
};

const favoriteMarketsStore = {
  namespaced: true,
  state: {
    // [sportId] -> [marketTypeId]: 1
    favoriteMarketTypesBySport: {},
  },
  mutations: {
    [mutationNames.ADD_MARKET_TYPE_ID](state, { sportId, marketTypeId }) {
      const marketTypes = state.favoriteMarketTypesBySport[sportId] || {};

      Vue.set(state.favoriteMarketTypesBySport, sportId, {
        ...marketTypes,
        [marketTypeId]: 1,
      });
    },
    [mutationNames.SET_MARKET_TYPES](state, marketTypes) {
      state.favoriteMarketTypesBySport = Object.keys(marketTypes)
        .reduce((bySport, sportId) => {
          bySport[sportId] = marketTypes[sportId].reduce((typeIdMap, typeId) => {
            typeIdMap[typeId] = 1;
            return typeIdMap;
          }, {});
          return bySport;
        }, {});
    },
    [mutationNames.REMOVE_MARKET_TYPE_ID](state, { sportId, marketTypeId }) {
      const marketTypesForSport = state.favoriteMarketTypesBySport[sportId];

      if (marketTypesForSport) {
        Vue.delete(marketTypesForSport, marketTypeId);
      }
    },
  },
  actions: {
    [actionNames.ADD_MARKET_TYPE_ID]({ commit }, payload) {
      commit(mutationNames.ADD_MARKET_TYPE_ID, payload);
    },
    [actionNames.SET_MARKET_TYPES]({ commit }, payload) {
      commit(mutationNames.SET_MARKET_TYPES, payload);
    },
    [actionNames.REMOVE_MARKET_TYPE_ID]({ commit }, payload) {
      commit(mutationNames.REMOVE_MARKET_TYPE_ID, payload);
    },
  },
  getters: {
    [getterNames.IS_FAVORITE_MARKET_TYPE](state) {
      return (sportId, marketTypeId) => (state.favoriteMarketTypesBySport[sportId]
        && !!state.favoriteMarketTypesBySport[sportId][marketTypeId]) || false;
    },
  },
};

export default favoriteMarketsStore;
