<script>
  import { mergeAOS } from '@/globals/utils/arrays';

  export default {
    name: `Icon`,
    functional: true,
    props: {
      name: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        required: false,
        default: `auto`,
      },
    },
    render(createElement, { props, children, data }) {
      const classes = mergeAOS({
        icon: true,
        [`icon--${props.name}`]: !!props.name,
        [`icon--size_${props.size}`]: !!props.size,
      }, data.class);

      return createElement(`div`, { ...data, class: classes }, children);
    },
  };
</script>

<style lang="scss">
  $base_class: '.icon';

  $icon_sizes: (
    xxxs: 8px,
    xxs: 10px,
    xs: 12px,
    s: 14px,
    m: 16px,
    l: 18px,
    auto: inherit,
  );

  #{$base_class} {
    display: inline-block;
  }

  @each $variant, $size in $icon_sizes {
    #{$base_class}--size_#{$variant} {
      font-size: $size;
    }
  }
</style>
