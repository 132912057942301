export const types = {
  FETCH_CONFIGURATION_PENDING: `FETCH_CONFIGURATION_PENDING`,
  FETCH_CONFIGURATION_DONE: `FETCH_CONFIGURATION_DONE`,
  FETCH_CONFIGURATION_ERROR: `FETCH_CONFIGURATION_ERROR`,
};

export const actions = {
  FETCH_CONFIGURATION_PENDING: `fetchConfigurationPending`,
  FETCH_CONFIGURATION_DONE: `fetchConfigurationDone`,
  FETCH_CONFIGURATION_ERROR: `fetchConfigurationError`,
};

export const getters = {
  GET_EC_CONFIG: `getEarlyCashoutConfig`,
};

const earlyCashoutConfigStore = {
  namespaced: true,
  state: {
    config: {},
    isLoading: false,
    isError: false,
  },
  mutations: {
    [types.FETCH_CONFIGURATION_PENDING](state) {
      state.isLoading = true;
      state.isError = false;
    },
    [types.FETCH_CONFIGURATION_DONE](state, config) {
      state.isLoading = false;
      state.isError = false;
      if (config) {
        state.config = config;
      }
    },
    [types.FETCH_CONFIGURATION_ERROR](state) {
      state.isLoading = false;
      state.isError = true;
    },
  },
  actions: {
    [actions.FETCH_CONFIGURATION_PENDING]({ commit }) {
      commit(types.FETCH_CONFIGURATION_PENDING);
    },
    [actions.FETCH_CONFIGURATION_DONE]({ commit }, config) {
      commit(types.FETCH_CONFIGURATION_DONE, config);
    },
    [actions.FETCH_CONFIGURATION_ERROR]({ commit }) {
      commit(types.FETCH_CONFIGURATION_ERROR);
    },
  },
  getters: {
    [getters.GET_EC_CONFIG](state) {
      return state.config || {};
    },
  },
};

export default earlyCashoutConfigStore;
