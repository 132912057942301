import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    leagues: {},
    collapsed: {
      generic: {},
      single: {},
      favorites: {},
      touched: {},
    },
  },
  mutations,
  actions,
  getters,
};
