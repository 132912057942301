import { ImpressionService } from '@fortunaweb-fe/frontend-commons';
import safeToString from 'lodash/toString';
import store from '@/config/store';
import { getters as matchStoreGetters } from '@/stores/LiveMatchStore';
import { getters as sportStoreGetters } from '@/stores/LiveSportStore';
import { getters as liveOddsStore } from '@/stores/LiveOddsStore';
import { addScriptDynamically } from '@/globals/utils/dom_utils';
import { SITE, resolveEndpoint } from '@/config/constants';
import {
  ROUTE_OVERVIEW,
  ROUTE_OVERVIEW_BY_SPORT,
  ROUTE_MATCH_DETAIL,
} from '@/config/router/routes';
import {
  gtmEvents, pageTypes,
  GTM_LIVE_TICKET_CHANNEL,
  GTM_SUCCESS,
  GTM_FAILURE,
  GTM_CODE_NOT_AVAILABLE,
  GTM_MESSAGE_NOT_AVAILABLE,
  EMPTY_FIELD, GTM_CONSOLE_ERROR,
} from '@/globals/enums/gtm.enums';
import api from '@/config/api/live';
import Lifecycle from '@/services/LifecycleHooks';
import { APP_BOOTSTRAP_DONE, APP_LOGIN_RESOLVED, APP_SSO_RESOLVED } from '@/globals/enums/eventBus/App.enums';
import * as userStoreGetters from "@/stores/UserStore/getters";
import { getters as ticketStoreGetters } from "@/stores/ticket/TicketStore";
import { getters as marketStoreGetters } from "@/stores/LiveMarketStore";
import { GET_LEAGUE_BY_ID } from "@/stores/LiveLeagueStore/constants";
import { APP_CONF_GET_CONF_VALUE } from "@/stores/AppConfigurationStore/constants";

class GTMService {
  constructor() {
    this.siteEnv = SITE;
    window.Playtech = window.Playtech || {};
    window.Playtech.API = window.Playtech.API || {};
    window.Playtech.API.user = window.Playtech.API.user || {};
    this.registerPublicMethods();

    Lifecycle.$on(APP_BOOTSTRAP_DONE, () => {
      this.getGtmIDAndAddScriptDynamically();
    });

    this.matchImpression = new ImpressionService(this.matchImpressionHandler.bind(this));
  }

  init({ router }) {
    Lifecycle.$on(APP_LOGIN_RESOLVED, ({ isManualLogin }) => {
      const userID = this.getUserId(this.getUserData().id);

      if (isManualLogin) {
        if (userID !== gtmEvents.GTM_USER_NOT_LOGGED) {
          const tracking = {
            status: GTM_SUCCESS,
            username: userID,
          };

          this.dispatchGTMEvent(gtmEvents.GTM_LOGIN, tracking);
          this.pageInformation({ router });
        }
      } else {
        this.dispatchGTMEvent(gtmEvents.GTM_APP_LOADED, { username: userID });
        if (userID === gtmEvents.GTM_USER_NOT_LOGGED) this.pageInformation({ router });
      }
    });

    Lifecycle.$on(APP_SSO_RESOLVED, () => {
      this.pageInformation({ router });
    });
  }

  registerPublicMethods() {
    window.Playtech.API.user.getPlayerTags = this.publicUserMethodFactory(`TRACKING_USER_TAGS`);

    window.Playtech.API.user.setPlayerTags = this.publicUserMethodFactory(`TRACKING_USER_TAGS`, api.post);

    window.Playtech.API.user.triggerCampaign = this.publicUserMethodFactory(`TRACKING_USER_TRIGGER_CAMPAIGN`, api.post);

    window.Playtech.API.user.getPlayerBonuses = this.publicUserMethodFactory(`TRACKING_USER_BONUSES`);

    window.Playtech.API.user.getPlayerBonusesActive = this.publicUserMethodFactory(`TRACKING_USER_BONUSES_ACTIVE`);

    window.Playtech.API.user.getPlayerBonusesOffered = this.publicUserMethodFactory(`TRACKING_USER_BONUSES_OFFERED`);

    window.Playtech.API.user.getPlayerBonusesHistory = this.publicUserMethodFactory(`TRACKING_USER_BONUSES_HISTORY`);

    window.Playtech.fetchUserDetails = this.publicUserMethodFactory(`TRACKING_USER_DETAILS`);

    window.Playtech.getUserBalance = this.publicUserMethodFactory(`TRACKING_USER_BALANCE`);

    window.Playtech.getUserPaymentStatistics = this.publicUserMethodFactory(`TRACKING_USER_PAYMENT_STATISTICS`);
  }

  publicUserMethodFactory(endpointName, apiMethod = api.get) {
    let payload;
    let onSuccess;

    return (...args) => {
      if (Object.keys(this.getUserData()).length === 0) return null;
      if (args.length === 1) [onSuccess] = args;
      if (args.length === 2) [payload, onSuccess] = args;

      if (endpointName === `TRACKING_USER_TAGS` && args.length === 1) {
        [payload] = args;
      }

      return apiMethod(resolveEndpoint(endpointName), payload)
        .then(({ data }) => onSuccess(data));
    };
  }

  matchImpressionHandler(pendingMatches) {
    if (!pendingMatches || !Object.keys(pendingMatches).length) return;

    pendingMatches = Object
      .values(pendingMatches)
      .map((matchId) => {
        const match = store.getters[`LiveStore/${matchStoreGetters.GET_MATCH_BY_ID}`](matchId);

        if (!match) return {};
        const league = store.getters[`LiveStore/${GET_LEAGUE_BY_ID}`](match.leagueId) || {};
        const sport = store.getters[`LiveStore/${sportStoreGetters.GET_SPORT_BY_ID}`](match.sportId) || {};

        return {
          item: {
            id: match.id || EMPTY_FIELD,
            name: match.names || EMPTY_FIELD,
          },
          contest: {
            id: league.id || EMPTY_FIELD,
            name: (league.names && league.names.toUpperCase()) || EMPTY_FIELD,
          },
          category: {
            id: sport.id || EMPTY_FIELD,
            name: sport.names || EMPTY_FIELD,
          },
          channel: GTM_LIVE_TICKET_CHANNEL,
        };
      });

    this.dispatchGTMEvent(gtmEvents.GTM_IMPRESSION, {
      username: this.getUserId(this.getUserData().id),
      impressions: pendingMatches,
    });
  }

  addToCart(marketData, marketOnBetslip) {
    if (marketOnBetslip?.selectedOdds?.id) {
      this.removeFromCart({ betIds: [marketOnBetslip.selectedOdds.id] });
    }
    const betVariant = store.getters[
      `LiveStore/${liveOddsStore.GET_BET_VARIANT_FROM_MARKET_BY_TIP_ID}`
    ](marketData.match.marketId, marketData.betId);
    const {
      id,
      leagueId,
      competitionNames,
      sportCategory,
      names,
    } = this.getSportDetailsByMarketId(marketData.match.marketId);

    const addToCartMarketData = {
      ...marketData,
      betId: safeToString(marketData.betId),
      betVariant,
      betChannel: GTM_LIVE_TICKET_CHANNEL,
      username: this.getUserId(this.getUserData().id),
      category: sportCategory || EMPTY_FIELD,
      contest: {
        name: (competitionNames && competitionNames.toUpperCase()) || EMPTY_FIELD,
        id: leagueId || EMPTY_FIELD,
      },
      teams: this.getTeams(names),
    };

    delete marketData.match.marketId;
    marketData.match.id = id;
    this.dispatchGTMEvent(gtmEvents.GTM_ADD_BET, addToCartMarketData);
  }

  removeFromCart(marketData) {
    const removeFromCartMarketData = {
      ...marketData,
      betId: marketData?.betIds?.map((betId) => safeToString(betId)),
      username: this.getUserId(this.getUserData().id),
    };

    delete removeFromCartMarketData.betIds;
    this.dispatchGTMEvent(gtmEvents.GTM_REMOVE_BET, removeFromCartMarketData);
  }

  betPlacement() {
    const ticketGTMData = this.getGTMTicketData();

    ticketGTMData.bets = Object
      .values(ticketGTMData.bets)
      .map(({ id, name, teams, ...bet }) => bet);
    const betPlacementPayload = {
      ...ticketGTMData,
      username: this.getUserId(this.getUserData().id),
      ticketChannel: GTM_LIVE_TICKET_CHANNEL,
    };

    this.dispatchGTMEvent(gtmEvents.GTM_BETSLIP_PLACEMENT, betPlacementPayload);
  }

  betAcceptance(betslipId, error) {
    const ticketGTMData = this.getGTMTicketData();

    ticketGTMData.bets = Object
      .values(ticketGTMData.bets)
      .map(({ id, name, ...bet }) => bet);

    const payload = {
      ...ticketGTMData,
      username: this.getUserId(this.getUserData().id),
      ticketChannel: GTM_LIVE_TICKET_CHANNEL,
      status: error ? GTM_FAILURE : GTM_SUCCESS,
    };

    if (betslipId) payload.betslipId = betslipId;

    if (error) {
      payload.error = {
        code: String(error.code || GTM_CODE_NOT_AVAILABLE),
        message: error.text || GTM_MESSAGE_NOT_AVAILABLE,
      };
    }
    this.dispatchGTMEvent(gtmEvents.GTM_BETSLIP_ACCEPTANCE, payload);
  }

  loginFailure(LoginDataFailure, username) {
    const tracking = {
      username: this.getUserId(username),
      status: GTM_FAILURE,
      error: {
        code: LoginDataFailure.errorCode || EMPTY_FIELD,
        message: LoginDataFailure.playerMessage || EMPTY_FIELD,
      },
    };

    this.dispatchGTMEvent(gtmEvents.GTM_LOGIN, tracking);
  }

  pageInformation({ router }) {
    const { name, params, path } = router.currentRoute;
    let GTMPageInformation = {
      event: gtmEvents.GTM_PAGE_INFORMATION,
      page: {
        type: pageTypes.EVENT,
        category: pageTypes.CATEGORY,
      },
      pagePath: path,
    };

    switch (name) {
      // eslint-disable-next-line no-case-declarations
      case ROUTE_OVERVIEW_BY_SPORT:
        if (params && params.sportId) {
          const { id, names } = store.getters[`LiveStore/${sportStoreGetters.GET_SPORT_BY_ID}`](params.sportId) || {};

          if (params.sportId === `FAVORITES`) {
            GTMPageInformation.category = {
              name: pageTypes.FAVOURITES,
              id: pageTypes.FAVOURITES.toUpperCase(),
            };
          } else if (id && names) {
            GTMPageInformation.category = {
              name: names,
              id,
            };
          } else {
            GTMPageInformation.category = pageTypes.ERROR;
          }
        } else {
          GTMPageInformation.category = pageTypes.ERROR;
        }
        GTMPageInformation.page.type = pageTypes.TYPE;
        break;
      case ROUTE_MATCH_DETAIL:
        if (params && params.matchId) {
          const match = store.getters[`LiveStore/${matchStoreGetters.GET_MATCH_BY_ID}`](params.matchId);
          const sportInfo = store.getters[`LiveStore/${sportStoreGetters.GET_SPORT_BY_ID}`](match.sportId);

          GTMPageInformation = {
            ...GTMPageInformation,
            contest: {
              name: (match.competitionNames && match.competitionNames.toUpperCase()) || EMPTY_FIELD,
              id: match.leagueId || EMPTY_FIELD,
            },
            item: {
              id: match.id || EMPTY_FIELD,
              name: match.names || EMPTY_FIELD,
            },
            teams: this.getTeams(match.names),
            category: {
              name: sportInfo.names || EMPTY_FIELD,
              id: sportInfo.id || EMPTY_FIELD,
            },
          };
        } else {
          GTMPageInformation.category = pageTypes.ERROR;
        }
        break;
      case ROUTE_OVERVIEW:
        GTMPageInformation.page.type = pageTypes.LOBBY;
        break;
      default:
    }

    this.dispatchGTMEvent(gtmEvents.GTM_PAGE_INFORMATION, {
      ...GTMPageInformation,
      username: this.getUserId(this.getUserData().id),
    });
  }

  dispatchGTMEvent = (eventName, gtmEventData) => {
    gtmEventData.event = eventName;
    this.dataLayerPush(gtmEventData);
  };

  getUserData = () => store.getters[`UserStore/${userStoreGetters.GET_USER_INFO}`];

  getUserId = (userId) => userId || gtmEvents.GTM_USER_NOT_LOGGED;

  getTeams = (teamNames) => {
    try {
      const teams = String(teamNames).split(` - `) || [];

      return teams.map((teamName) => ({ id: this.strToIdentifier(teamName), name: teamName }));
    } catch (e) {
      console.error(`${GTM_CONSOLE_ERROR}: ${e.message}`);
      return [];
    }
  };

  getGTMTicketData = () => {
    const { items, mode } = store.getters[`TicketStore/${ticketStoreGetters.GET_TICKET}`];
    const bets = items.filter((item) => item.signature !== `BONUS`).map(({ marketId, name, selectedOdds }) => {
      const {
        leagueId,
        competitionNames,
        sportCategory,
        id,
        names,
      } = this.getSportDetailsByMarketId(marketId);

      return {
        id: selectedOdds.oddsId,
        name,
        betRatio: selectedOdds.value,
        match: {
          name: names,
          id,
        },
        teams: this.getTeams(names),
        contest: {
          name: (competitionNames && competitionNames.toUpperCase()) || EMPTY_FIELD,
          id: leagueId || EMPTY_FIELD,
        },
        category: sportCategory || EMPTY_FIELD,
      };
    });
    const betAmount = store.getters[`TicketStore/${ticketStoreGetters.GET_TICKET_TOTAL_BET}`];
    const payOff = store.getters[`TicketStore/${ticketStoreGetters.GET_TICKET_TOTAL_WIN}`];

    return { ticketType: mode, betAmount, payOff, bets };
  };

  dataLayerPush = (gtmEventData) => {
    if (!window.dataLayer) return;
    window.dataLayer.push(gtmEventData);
  };

  getSportDetailsByMarketId = (marketId) => {
    try {
      const { matchId } = store.getters[`LiveStore/${marketStoreGetters.GET_MARKET_BY_MARKET_ROW_ID}`](marketId);
      const matchDetails = store.getters[`LiveStore/${matchStoreGetters.GET_MATCH_BY_ID}`](matchId);
      const { names, id } = store.getters[`LiveStore/${sportStoreGetters.GET_SPORT_BY_ID}`](matchDetails.sportId);

      return {
        ...matchDetails,
        sportCategory: {
          name: names,
          id,
        },
      };
    } catch (e) {
      console.error(`Cant get sport details from market ID ${marketId}\n${GTM_CONSOLE_ERROR} : ${e.message}`);
      return {};
    }
  };

  strToIdentifier = (originalString) => originalString
    .normalize(`NFD`)
    .toLocaleLowerCase()
    .replace(/[\u0300-\u036f]|\s|\.|,|\/|'|"|-|\*/g, ``);

  getGtmIDAndAddScriptDynamically() {
    const getConfigValue = store.getters[`AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`];
    const gtmId = getConfigValue(`gtmId`);

    if (!gtmId) return;

    let script = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

    const gtmAuth = getConfigValue(`gtmAuth`);
    const gtmPreview = getConfigValue(`gtmPreview`);

    if (gtmAuth) script += `&gtm_auth=${gtmAuth}`;
    if (gtmPreview) script += `&gtm_preview=${gtmPreview}`;
    if (gtmAuth && gtmPreview) script += `&gtm_cookies_win=x`;

    addScriptDynamically(script, `ftn_gtm`)
      .then(() => {
        this.dataLayerPush({ 'gtm.start': new Date().getTime(), event: `gtm.js` });
        this.setGTMDefaultConsent(`consent`, `default`, { ad_storage: `denied`, analytics_storage: `denied` });
      });
  }

  setGTMDefaultConsent() {
    // eslint-disable-next-line prefer-rest-params
    this.dataLayerPush(arguments);
    this.dataLayerPush({ event: gtmEvents.GTM_DEFAULT_CONSENT });
  }
}

export const gtmService = new GTMService();
