import translation from '@/plugins/translation';

export const KOM_KEY = `komunikator_`;

/**
 * @typedef {Object} AlertMessage
 * @property {String} key
 * @property {String} severity
 * @property {String} message
 */

/**
 *
 * @param key - translation key that will be translated via translation plugin
 * @param severity
 * @param text - text is already translated string, so there is no need to translate it again
 * @param code - is set if response is coming from KOM
 * @returns {AlertMessage}
 */
export function AlertMessage({ key, severity, text, code }) {
  if (!text) text = translation.getTranslatedMessage(key);
  if (code) key = `${KOM_KEY}.${code}`;

  return {
    key,
    severity,
    text,
  };
}
