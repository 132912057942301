export const USER_STATUS_SUSPENDED = `SUSPENDED`;
export const USER_STATUS_EXPIRED = `EXPIRED`;
export const USER_STATUS_DEACTVATED = `DEACTVATED`;
export const USER_STATUS_ANONYMOUS = `ANONYMOUS`;
export const USER_STATUS_OLD_AGREEMENT = `OLD_AGREEMENT`;
export const USER_STATUS_FULL = `FULL`;
export const USER_STATUS_FULL_SB = `FULL_SB`;
export const USER_STATUS_TEMPORARY = `TEMPORARY`;
export const USER_STATUS_LIMITED_TEMPORARY = `LIMITED`;
export const USER_STATUS_PREREGISTERED = `PREREGISTERED`;
export const USER_STATUS_RETAIL = `RETAIL`;
export const USER_STATUS_MULTICHANNEL = `MULTICHANNEL`;
export const USER_STATUS_EXCLUDED = `EXCLUDED`;
