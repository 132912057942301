import Cookies from 'js-cookie';

/**
 * Get key value pair {[cookieName]: value} based on input regex
 *
 * @param regex
 * @returns {Array}
 */
export function getCookiesByRegExp(regex) {
  return Object.entries(Cookies.get())
    .filter(([name]) => name.match(regex))
    .map(([name, value]) => ({ [name]: value }));
}

export function getCookieByName(name) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([$?*|{}\]\\^])/g, `\\$1`)}=([^;]*)`),
  );

  if (matches) return decodeURIComponent(matches[1]) === `true`;
  return false;
}
