import { Translation } from '@fortunaweb-fe/frontend-commons';
import { getLocale } from '@/config/locale';
import csCzMessages from '@/locales/cs_CZ';
import skSkMessages from '@/locales/sk_SK';
import plPlMessages from '@/locales/pl_PL';
import roRoMessages from '@/locales/ro_RO';
import enUsMessages from '@/locales/en_US';
import hrHrMessages from '@/locales/hr_HR';

import {
  LOCALE_CS_CZ,
  LOCALE_EN_US,
  LOCALE_HR_HR,
  LOCALE_PL_PL,
  LOCALE_RO_RO,
  LOCALE_SK_SK,
} from '@/globals/enums/Locale.enums';

const translation = new Translation(getLocale(), {
  [LOCALE_CS_CZ]: csCzMessages,
  [LOCALE_SK_SK]: skSkMessages,
  [LOCALE_PL_PL]: plPlMessages,
  [LOCALE_RO_RO]: roRoMessages,
  [LOCALE_EN_US]: enUsMessages,
  [LOCALE_HR_HR]: hrHrMessages,
});

export default translation;
