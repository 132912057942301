/* W3C Media errors
* More read: https://developer.mozilla.org/en-US/docs/Web/API/MediaError
* @property {string} 0 - MEDIA_ERR_CUSTOM
* @property {string} 1 - MEDIA_ERR_CUSTOM
* @property {string} 2 - MEDIA_ERR_ABORTED
* @property {string} 3 - MEDIA_ERR_NETWORK
* @property {string} 4 - MEDIA_ERR_SRC_NOT_SUPPORTED
* @property {string} 5 - MEDIA_ERR_ENCRYPTED
*/
import { AlertMessage } from '@/factories/AlertMessage';
import safeGet from 'lodash/get';

const ASPECT_RATIO = 16 / 9;
const SCREEN_WIDTH = () => window.screen.width;
const SCREEN_HEIGHT = () => window.screen.height;
const SCREEN_PIXEL_COUNT = () => SCREEN_WIDTH() * SCREEN_HEIGHT();
const DEFAULT_MAX_SIZE_PERCENTAGE = 30;

export const getStreamSize = (provider, {
  maxPlayerSizePercentage = DEFAULT_MAX_SIZE_PERCENTAGE,
}) => {
  if (maxPlayerSizePercentage < 0 || maxPlayerSizePercentage > 100) {
    console.warn(
      `Stream max size was set outside allowed percentage range [0, 100].
      Setting to default ${DEFAULT_MAX_SIZE_PERCENTAGE}%`,
    );
    maxPlayerSizePercentage = DEFAULT_MAX_SIZE_PERCENTAGE;
  }

  const heightPixel = Math.floor(Math.sqrt(((maxPlayerSizePercentage / 100) * (1 / ASPECT_RATIO)) * SCREEN_PIXEL_COUNT()));
  const widthPixel = Math.floor(ASPECT_RATIO * heightPixel);

  switch (provider) {
    case `LIVEBOX`:
      return {
        height: Math.min(heightPixel, 360),
        width: Math.min(widthPixel, 640),
      };
    default:
      return {
        height: heightPixel,
        width: widthPixel,
      };
  }
};

const STREAM_ERROR_KEY_TRANSLATION_MAP = {
  0: `live3.stream.media_error.MEDIA_ERR_CUSTOM`,
  1: `live3.stream.media_error.MEDIA_ERR_CUSTOM`,
  2: `live3.stream.media_error.MEDIA_ERR_ABORTED`,
  3: `live3.stream.media_error.MEDIA_ERR_NETWORK`,
  4: `live3.stream.media_error.MEDIA_ERR_SRC_NOT_SUPPORTED`,
  5: `live3.stream.media_error.MEDIA_ERR_ENCRYPTED`,
  'Network Error': `live3.stream.error.could_not_load`,
  'Request failed with status code 401': `live3.stream.error.not_authorized`,
  'Request failed with status code 500': `live3.stream.error.no_channel_id`,
};

export const getTranslationKeyByStreamError = (errorIdentifier) => (STREAM_ERROR_KEY_TRANSLATION_MAP[errorIdentifier]) || errorIdentifier;

export const getStreamErrorMessage = (err) => {
  const errorMessage = safeGet(err, `response.data.messages.0`) || safeGet(err, `message`);
  const translationKey = getTranslationKeyByStreamError(errorMessage);

  return AlertMessage({
    key: translationKey,
  });
};
