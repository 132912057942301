import * as mutationTypes from './mutationTypes';
import * as actions from './actions';
import * as getters from './getters';
import { parseDateStringToUtc } from '../../globals/utils/date';

const timeStore = {
  namespaced: true,
  state: {
    serverClock: ``,
    serverTime: null,
    offsetMinutes: null,
    zone: null,
    timeSinceLastLogin: 0,
  },
  mutations: {
    [mutationTypes.SET_SERVER_CLOCK]: (state, serverClock) => {
      state.serverClock = serverClock;
    },
    [mutationTypes.SET_TIME_SINCE_LAST_LOGIN]: (state, timeSinceLastLogin) => {
      state.timeSinceLastLogin = timeSinceLastLogin;
    },
    [mutationTypes.SET_ZONE]: (state, zone) => {
      state.zone = zone;
    },
    [mutationTypes.SET_SERVER_TIME]: (state, serverTime) => {
      state.serverTime = serverTime;
    },
    [mutationTypes.SET_OFFSET_MINUTES]: (state, offsetMinutes) => {
      state.offsetMinutes = offsetMinutes;
    },
  },
  actions: {
    [actions.SERVER_TIME_ACTION]: ({ commit }, { serverTime, offsetMinutes, zone }) => {
      commit(mutationTypes.SET_SERVER_TIME, serverTime);
      commit(mutationTypes.SET_OFFSET_MINUTES, offsetMinutes);
      commit(mutationTypes.SET_ZONE, zone);
    },
  },
  getters: {
    [getters.SERVER_CLOCK]: (state) => state.serverClock,
    [getters.TIME_SINCE_LAST_LOGIN]: (state) => state.timeSinceLastLogin,
    [getters.SERVER_TIME]: (state) => state.serverTime,
    [getters.ZONE]: (state) => state.zone,
    [getters.OFFSET_MINUTES]: (state) => state.offsetMinutes,

    /**
     * Returns datetime shifted to server timezone or current server datetime if
     * {@link epochMillis} is not provided.
     *
     * Warning: Note that current server datetime is polled in fixed intervals (minutes) and
     * therefore this value should not be used for time sensitive applications.
     *
     * @param {Number} epochMillis
     * @returns {Date}TZDate
     */
    [getters.TZDATE]: (state) => (epochMillis = parseDateStringToUtc(state.serverTime)) => {
      const localZoneOffset = new Date().getTimezoneOffset();

      return new Date(epochMillis + ((localZoneOffset + state.offsetMinutes) * 60000));
    },
  },
};

export default timeStore;
