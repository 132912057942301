import { resolveEndpoint } from '@/config/constants/index';
import api from '@/config/api/live';

export async function obtainIpAuthorizationToken() {
  const response = await api.get(resolveEndpoint(`LIVESTREAM_TOKEN`), { params: { liveStreamType: `IMG_WIDGET` } });

  if ([401, 403].includes(response.status)) {
    return null;
  }
  if (response.status !== 200) {
    throw new Error(`Unexpected response`);
  }

  return response.data.data;
}
