import { BET_PERMISSION, CHAT_PERMISSION, REGISTERED_PERMISSION } from '@/globals/enums/UserPermissions';
import { FortunaSite } from '@/globals/enums/enums';
import { USER_STATUS_EXCLUDED, USER_STATUS_LIMITED_TEMPORARY, USER_STATUS_TEMPORARY } from '@/globals/enums/userStatus';
import { USER_ABP_TEMPORARY } from '@/globals/enums/userAccountBusinessPhase';
import { SITE } from '@/config/constants';

/**
 * Service for limiting user permissions
 * @returns {{getUserPermission: getUserPermission}}
 */
const PermissionService = () => {
  /**
   * if TRUE, user has permission to:
   * @param betPerm - place a bet
   * @param chatPerm - write into chat
   * @param registeredPerm - do stuff, that needs user to be registred
   */
  const permFactory = ({ betPerm = true, chatPerm = true, registeredPerm = true }) => ({
    [BET_PERMISSION]: betPerm,
    [CHAT_PERMISSION]: chatPerm,
    [REGISTERED_PERMISSION]: registeredPerm,
  });

  const permissions = {
    [FortunaSite.CZ]: {},
    [FortunaSite.SK]: {
      [USER_STATUS_EXCLUDED]: permFactory({
        betPerm: false, chatPerm: false,
      }),
    },
    [FortunaSite.PL]: {
      [USER_STATUS_TEMPORARY]: permFactory({
        chatPerm: false, registeredPerm: false,
      }),
      [USER_STATUS_LIMITED_TEMPORARY]: permFactory({
        betPerm: false, chatPerm: false, registeredPerm: false,
      }),
      [USER_ABP_TEMPORARY]: permFactory({
        chatPerm: false, registeredPerm: false,
      }),
    },
    [FortunaSite.RO]: {},
    [FortunaSite.CP]: {},
    [FortunaSite.HR]: {},
  };

  return {
    getUserPermission: (status, permissionType, site = SITE) => {
      const statusPermissions = permissions[site][status];

      if (statusPermissions === undefined) return true;
      return statusPermissions[permissionType];
    },
  };
};

export default PermissionService();
